import { baseDetail } from '@/api/product/checkProdApi'
import { getPicName, getCanvasBySrc } from '@/utils'

const COLOR = '#fff'
const NO_CHANGE_COLOR = '#000'
const BACKGROUND_COLOR = '#eee'
const FILL_COLOR_1 = '#70ad47'
const FILL_COLOR_2 = '#fff'
const DRAW_TYPE_ORIGIN = 'origin'
const DRAW_TYPE_NO_CHANGGE = 'noChange'
const DRAW_TYPE_NOW = 'now'
const GLOBAL_ALPHA_NUM = 0.3 //图片透明度
const LINE_ODD_COLOR = '#004c60'
const FILL_LINE_COLOR = '#fff'
const LINE_EVEN_COLOR = '#0070c0'
const HIGH_BG_COLOR = '#70ad47'

import {
  MAKER, //膜层
  SECURITY, //安全性
  BOUNDARY //边界线
} from '@/utils/constant'

export default {
  data() {
    return {
      drawType: DRAW_TYPE_NOW,
      width: 0,
      height: 0,
      dpi: 0,
      loading: false,
      isUpdateLoading: false, //更新
      gridSizeOfCm: this.$route.query.sizeCheckNum ? +this.$route.query.sizeCheckNum : 8,
      //参考尺码
      referenceId: this.$route.query.referenceId,
      //缓存背景画布
      backgroundCanvas: null,
      globalAlpha: GLOBAL_ALPHA_NUM
    }
  },

  created() {
    this.init()
  },

  computed: {
    isLack() {
      return !+this.$route.query.isUpdate
    },

    canvas() {
      return this.$refs.canvas
    }
  },

  methods: {
    //连续图
    drawOriginType() {
      this.drawType = DRAW_TYPE_ORIGIN
      this.updateGripCanvas()
    },

    //经纬图
    drawNowType() {
      this.drawType = DRAW_TYPE_NOW
      this.updateGripCanvas()
    },

    //旧刀版
    drawNoChangeType() {
      this.drawType = DRAW_TYPE_NO_CHANGGE
      this.updateGripCanvas()
    },

    //创建切割的网格图
    createGridsClipPics() {
      const { width, height } = this.canvas
      const cloneCanvas = document.createElement('canvas')
      cloneCanvas.width = width
      cloneCanvas.height = height
      const ctx = cloneCanvas.getContext('2d')
      const dpiCmFromIn = this.dpi / 2.54 //物理尺寸
      const dipOfCm = dpiCmFromIn * this.gridSizeOfCm
      ctx.fillStyle = BACKGROUND_COLOR
      ctx.fillRect(0, 0, width, height)
      this.drawGrid(dipOfCm, ctx)
      const groups = this.referGroupData.referSizeLayerGroupList.map(({ referSizeLayerGroupImageList }) => {
        return referSizeLayerGroupImageList.find((item) => item.type == MAKER)
      })
      const gridsClipPics = groups.map(({ left: x, top: y, width, height }) => {
        const gridData = ctx.getImageData(x, y, width, height)
        const mC = document.createElement('canvas')
        mC.width = width
        mC.height = height
        const mCtx = mC.getContext('2d')
        mCtx.fillRect(0, 0, width, height)
        mCtx.putImageData(gridData, 0, 0)
        const base64Png = mC.toDataURL()
        return base64Png
      })
      return { gridsClipPics, groups, cloneCanvas }
    },

    watchGridSizeOfCm() {
      this.$watch('gridSizeOfCm', () => {
        this.queryTimer && clearTimeout(this.queryTimer)
        this.queryTimer = setTimeout(() => {
          this.updateGripCanvas()
        }, 300)
      })
    },

    async updateGripCanvas() {
      this.loading = true
      const dpiCmFromIn = this.dpi / 2.54 //物理尺寸
      const dipOfCm = dpiCmFromIn * this.gridSizeOfCm
      const c = this.canvas
      const ctx = c.getContext('2d')
      ctx.clearRect(0, 0, c.width, c.height)
      ctx.fillStyle = BACKGROUND_COLOR
      ctx.fillRect(0, 0, c.width, c.height)
      if (this.drawType === DRAW_TYPE_NO_CHANGGE) {
        await this.drawCanvasBackground(ctx)
        this.drawGrid(dipOfCm, ctx)
      } else {
        this.drawGrid(dipOfCm, ctx)
        await this.drawCanvasBackground(ctx)
      }

      this.loading = false
    },

    getFontSize(num, gridSize, ctx) {
      let fontSize = 20
      num = parseInt(num)
      const numLeng = num.toString().length
      ctx.font = `bold ${fontSize * this.gridSizeOfCm}px serif`
      const size = Math.min(this.getDecimalFontSize(numLeng, fontSize, gridSize, ctx), 70)
      return size
    },

    getDecimalFontSize(numLeng, fontSize, gridSize, ctx) {
      let txt = '8'.repeat(numLeng)
      const tW = ctx.measureText(txt).width
      let size = fontSize
      ctx.font = `bold ${fontSize * this.gridSizeOfCm}px serif`
      if (tW >= gridSize * 1) {
        return size
      } else {
        size = this.getDecimalFontSize(numLeng, ++fontSize, gridSize, ctx)
      }
      return size
    },

    drawGrid(gridSize, ctx) {
      if(this.drawType === DRAW_TYPE_NO_CHANGGE) {
        this.drawGridContactMap(gridSize, ctx)
      } else  {
        this.drawGridLongitudeAndlatitudeMap(gridSize, ctx)
      }
    },

    drawGridLongitudeAndlatitudeMap(gridSize, ctx) {
      let canvasWidth = ctx.canvas.width
      let canvasHeight = ctx.canvas.height

      let xLineTotal = Math.ceil(canvasHeight / gridSize)
      let yLineTotal = Math.ceil(canvasWidth / gridSize)
      let FONT_SIZE = this.getFontSize(Math.max(xLineTotal, yLineTotal), gridSize, ctx)
      if (this.drawType === DRAW_TYPE_ORIGIN) {
        FONT_SIZE = this.getFontSize(xLineTotal * yLineTotal, gridSize, ctx)
      }
      ctx.fillStyle = COLOR
      for (let i = 0; i <= xLineTotal; i++) {
        const drawSize = Math.min(canvasHeight, Math.max(0, i * gridSize - 0.5))
        ctx.beginPath()
        ctx.moveTo(0, drawSize)
        ctx.lineTo(canvasWidth, drawSize)
        ctx.strokeStyle = COLOR
        ctx.stroke()
      }

      for (let i = 0; i <= yLineTotal; i++) {
        const drawSize = Math.min(canvasWidth, Math.max(0, i * gridSize - 0.5))
        ctx.beginPath()
        ctx.moveTo(drawSize, 0)
        ctx.lineTo(drawSize, canvasHeight)
        ctx.strokeStyle = COLOR
        ctx.stroke()
      }

      ctx.font = `bold ${FONT_SIZE * this.gridSizeOfCm}px serif`
      const lineWidth = this.getLindWidth(gridSize)
      for (let i = 0; i <= xLineTotal; i++) {
        for (let j = 0; j < yLineTotal; j++) {
          //需要着色的位置
          let num = this.drawType == DRAW_TYPE_ORIGIN ? i * yLineTotal + j + 1 : i + 1 + j
          const colorPosNum = i + 1 + j
          let gridFillColor = LINE_ODD_COLOR
          if (colorPosNum % 2) {
            gridFillColor = j % 2 ? HIGH_BG_COLOR : LINE_EVEN_COLOR
          }
          ctx.fillStyle = gridFillColor
          ctx.fillRect(j * gridSize, i * gridSize, gridSize, gridSize)

          if (i % 2) {
            this.$nextTick(() => {
              ctx.fillStyle = FILL_LINE_COLOR
              ctx.fillRect(j * gridSize, i * gridSize - lineWidth / 2, gridSize, lineWidth)
              ctx.fillRect(j * gridSize, i * gridSize + 1 * gridSize - lineWidth / 2, gridSize, lineWidth)
            })
          }
          if (j % 2) {
            this.$nextTick(() => {
              ctx.fillStyle = FILL_LINE_COLOR
              ctx.fillRect(j * gridSize, i * gridSize - lineWidth / 2, lineWidth, gridSize)
              ctx.fillRect(j * gridSize + 1 * gridSize - lineWidth / 2, i * gridSize, lineWidth, gridSize)
            })

            ctx.fillStyle = COLOR
            let txtNum = num - Math.floor(j / 2) - 1 + ''
            //连续图
            if (this.drawType === DRAW_TYPE_ORIGIN) {
              txtNum = Math.floor(Math.floor(yLineTotal / 2) * i + j / 2 + 1)
            }
            ctx.fillText(
              txtNum,
              j * gridSize - 0.5 + gridSize / 2 - ctx.measureText(txtNum + '').width / 2,
              i * gridSize - 0.5 + gridSize / 2 + (FONT_SIZE * this.gridSizeOfCm) / 3
            )
          }
        }
      }
    },

    drawGridContactMap(gridSize, ctx) {
      let canvasWidth = ctx.canvas.width
      let canvasHeight = ctx.canvas.height

      let xLineTotal = Math.ceil(canvasHeight / gridSize)
      let yLineTotal = Math.ceil(canvasWidth / gridSize)
      const FONT_SIZE = this.getFontSize(xLineTotal * yLineTotal, gridSize, ctx)
      ctx.fillStyle = NO_CHANGE_COLOR
      for (let i = 0; i <= xLineTotal; i++) {
        const drawSize = Math.min(canvasHeight, Math.max(0, i * gridSize - 0.5))
        ctx.beginPath()
        ctx.moveTo(0, drawSize)
        ctx.lineTo(canvasWidth, drawSize)
        ctx.strokeStyle = NO_CHANGE_COLOR
        ctx.stroke()
      }

      for (let i = 0; i <= yLineTotal; i++) {
        const drawSize = Math.min(canvasWidth, Math.max(0, i * gridSize - 0.5))
        ctx.beginPath()
        ctx.moveTo(drawSize, 0)
        ctx.lineTo(drawSize, canvasHeight)
        ctx.strokeStyle = NO_CHANGE_COLOR
        ctx.stroke()
      }
      ctx.font = `${FONT_SIZE * this.gridSizeOfCm}px serif`
      for (let i = 0; i <= xLineTotal; i++) {
        for (let j = 0; j < yLineTotal; j++) {
          //需要着色的位置
          let num = i * yLineTotal + j + 1
          ctx.fillStyle = NO_CHANGE_COLOR
          ctx.fillText(
            num + '',
            j * gridSize - 0.5 + gridSize / 2 - ctx.measureText(num).width / 2,
            i * gridSize - 0.5 + gridSize / 2 + (FONT_SIZE * this.gridSizeOfCm) / 3
          )
        }
      }
    },

    getLindWidth(gridSize) {
      return gridSize * 0.025
    },

    async drawLayer(ctx, layers, layerType, radio, globalAlpha) {
      const { max } = Math
      const layer = layers.find(({ type }) => type == layerType)
      if (layer) {
        let { type, left, top, imagePath, width, height } = layer
        const compressSize = parseInt(max(width, height) / radio)
        const compressStr = `?x-oss-process=image/resize,l_${compressSize},limit_0`
        if (radio > 1) {
          imagePath += compressStr
        }
        if (type == layerType) {
          const c = await getCanvasBySrc(imagePath)
          const [l, t] = [left / radio, top / radio]
          if (globalAlpha < 1) {
            ctx.save()
            ctx.globalAlpha = globalAlpha
            ctx.drawImage(c, l, t)
            ctx.restore()
          } else {
            ctx.drawImage(c, l, t)
          }
        }
      }
    },

    //绘画出画布背景
    async drawCanvasBackground(tCtx) {
      if (this.backgroundCanvas) {
        if (this.drawType === DRAW_TYPE_NO_CHANGGE) {
          tCtx.drawImage(this.backgroundCanvas, 0, 0)
        }
      }
      const {
        referSizePsdConfig: { imageHeight = 0, imageWidth = 0 },
        referSizeLayerGroupList
      } = this.referGroupData
      const radio = 1
      const [dW, dH] = [imageWidth / radio, imageHeight / radio]
      let c = document.createElement('canvas')
      c.width = dW
      c.height = dH
      const ctx = c.getContext('2d')
      const pArr = referSizeLayerGroupList.map(async (group) => {
        const { referSizeLayerGroupImageList } = group
        let globalAlpha = this.globalAlpha
        //旧刀版数据透明度是1
        if (this.drawType === DRAW_TYPE_NO_CHANGGE) globalAlpha = 1
        await this.drawLayer(ctx, referSizeLayerGroupImageList, MAKER, radio, globalAlpha)
        await this.drawLayer(ctx, referSizeLayerGroupImageList, SECURITY, radio, 1)
        await this.drawLayer(ctx, referSizeLayerGroupImageList, BOUNDARY, radio, 1)
        return true
      })
      await Promise.all(pArr)
      //缓存背景画布
      this.backgroundCanvas = c
      tCtx.drawImage(c, 0, 0)
    },

    getResetGridSize(coordKl) {
      const picName = getPicName(coordKl || '')
      const tmpArr = picName.split('_')
      return parseInt(tmpArr[tmpArr.length - 1])
    },

    initData(detail) {
      const { imageWidth = 0, imageHeight = 0, vdpi } = detail.referSizePsdConfig || {}
      this.width = imageWidth
      this.height = imageHeight
      //初始化画布宽高
      this.canvas.width = imageWidth
      this.canvas.height = imageHeight

      this.dpi = vdpi
      this.referGroupData = detail
    },

    async baseDetail() {
      const { detail = {}, code } =
      (await baseDetail({
        id: this.referenceId
      })) || {}
      try {
        if ($SUC({ code })) {
          return detail
        }
        return false
      } catch (err) {
        return false
      }
    }
  }
}
