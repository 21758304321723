<template>
  <section class="app-container app-flex-col-container createCoordPicPage" v-loading="loading" id="knifeProCode">
    <div class="content-wrapper">
      <div class="title">{{ isLack ? '创建' : '更新' }}参考图</div>
      <div class="header">
        <div class="slider-wrapper">
          <el-slider v-model="gridSizeOfCm" :min="0.5" :step="0.5" :max="30"></el-slider>
          <div class="label">{{ gridSizeOfCm }}cm/格</div>
        </div>
        <div class="slider-wrapper">
          <el-slider v-model="globalAlpha" :min="0" :step="0.05" :max="1"> </el-slider>
          <div class="label">{{ globalAlpha }}透明度</div>
        </div>
        <div class="btn-wrapper">
          <el-button @click="drawNowType">经纬图</el-button>
          <el-button @click="drawOriginType">连续图</el-button>
          <el-button @click="drawNoChangeType">旧刀版</el-button>
        </div>
        <div class="info">
          <span class="label">宽：</span>
          <span class="value">{{ this.width }}px</span>
          <span class="label">高：</span>
          <span class="value">{{ this.height }}px</span>
        </div>

        <!-- <el-button size="mini" type="primary" :loading="loading" class="label" @click="clipCoordPic"
          >生成参考图</el-button
        >-->

        <Popover @sureHandler="clipCoordPic($event)">
          <template #tip>
            <p>参考图已经存在，确定要更新吗？此操作不能撤销！</p>
          </template>
          <template #reference="{ scope: loading }">
            <el-button class="update-btn" :loading="loading" size="mini" type="danger" v-show="isLack === false"
              >更新参考图</el-button
            >
          </template>
        </Popover>
        <el-button
          size="mini"
          type="primary"
          v-if="isLack === true"
          :loading="loading"
          class="label"
          @click="clipCoordPic($event)"
          >生成参考图</el-button
        >

        <el-button size="mini" :loading="loading" class="label" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
    <div class="canvas-wrapper flex-col-content" element-loading-text="加载中..." v-loading="isUpdateLoading">
      <div class="wrapper">
        <canvas class="canvas" id="canvas" ref="canvas" :width="width" :height="height"></canvas>
      </div>
    </div>
  </section>
</template>

<script>
import { createKnifeReference } from '@/api/product/checkProdApi'
import createCheckCanvasMixin from '../../mixins/createCheckCanvasMixin'

export default {
  mixins: [createCheckCanvasMixin],

  data() {
    return {
      referGroupData: []
    }
  },

  methods: {
    async init() {
      const id = this.$route.query.protoId
      if (!id) {
        return this.$router.back()
      }
      //获取参考尺码数据
      this.loading = true
      try {
        const detail = await this.baseDetail()
        if (detail) {
          this.initData(detail)
          await this.$nextTick()
          this.drawNowType()
          // this.updateGripCanvas()
          // this.watchGridSizeOfCm()
        }
      } catch {}
      this.loading = false
    },

    async clipCoordPic($el) {
      $el && $el.$refs && $el.$refs.popover.doClose()
      this.loading = true
      try {
        const { gridsClipPics, groups } = this.createGridsClipPics()
        if (gridsClipPics.length !== this.referGroupData.referSizeLayerGroupList.length) {
          return this.$message.error('參考尺码组与psd的组数量不一致！')
        }

        const { protoId: productPrototypeId, referenceId: referSizeId } = this.$route.query
        const params = {
          referSizeId,
          gridsClipPics,
          productPrototypeId,
          gridSize: this.gridSizeOfCm,
          sizePsdGroupCheckList: groups.map(({ id }) => id)
        }
        const { code } = await createKnifeReference(params)
        if ($SUC({ code })) {
          this.$message.success('提交成功')
          this.$emit('createCoordPicSuccess')
          this.$router.back()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.createCoordPicPage.createCoordPicPage {
  width: 100%;
  padding-right: 40px;
  .content-wrapper {
    padding: 15px;
    background: #fff;
  }
  .title {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid $border-color;
  }
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
  }
  .btn-wrapper {
    margin-right: 15px;
    .el-button {
      margin: 0;
      margin-right: 5px;
    }
  }
  .update-btn.update-btn.update-btn,
  .el-button {
    height: 38px;
    line-height: 38px !important;
    height: 38px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 20px;
  }
  .slider-wrapper {
    display: flex;
    width: 300px;
    align-items: center;
    margin-right: 20px;
    padding: 0 20px;
    border-radius: 2px;
    background: #eee;
    .el-slider {
      flex: 1;
    }
    .label {
      margin-left: 10px;
      font-size: 16px;
      white-space: nowrap;
      color: $color-primary;
    }
  }
  .info {
    display: inline-block;
    padding: 10px 20px;
    background: #eee;

    .value {
      margin-right: 20px;
    }
  }
  .canvas-wrapper {
    overflow: hidden;
    padding: 10px;
    .wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
      border: 1px solid $color-primary;

      .canvas {
        box-shadow: 0 0 5px 1px #666;
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
</style>
